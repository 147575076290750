interface Params {
  title: string
  description: string
  image?: string
}

export function useSeo(params: Params) {
  const { title, description, image } = params

  useSeoMeta({
    title: `${title}`,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImage: image || '/og-image.png',
    twitterCard: 'summary_large_image',
    twitterTitle: title,
    twitterDescription: description,
    twitterSite: '@zenodotfm',
    twitterImage: image || '/og-image.png',
  })
}
